import React from 'react';
import Header from '../layout/Header';
import Footer from '../layout/Footer';
import styled from 'styled-components';
import 'lightbox2/dist/css/lightbox.css';
import 'lightbox2/dist/js/lightbox-plus-jquery.js';

const Gallery = () => {
	return (
		<>
			<Header />

			<TitleImageWrapper>
				<ShadowEffect />
				<Img
					src="https://firebasestorage.googleapis.com/v0/b/moline-3ac91.appspot.com/o/iStock-859916128%20(1).jpg?alt=media&token=edef5213-30b3-4bbb-bf88-3ce1f26f951c"
					alt="img"
				/>
				<MainHomeHeader>Galéria</MainHomeHeader>
			</TitleImageWrapper>

			<Main>
				<a
					href="https://firebasestorage.googleapis.com/v0/b/moline-3ac91.appspot.com/o/315893483_674099304084296_3244177236350884666_n.jpg?alt=media&token=d36bb4ad-53a7-421d-ae55-f473d961411f"
					data-lightbox="roadtrip"
				>
					<ImgWrapper>
						<ImgBox
							src="https://firebasestorage.googleapis.com/v0/b/moline-3ac91.appspot.com/o/315893483_674099304084296_3244177236350884666_n.jpg?alt=media&token=d36bb4ad-53a7-421d-ae55-f473d961411f"
							alt="Img"
						/>
					</ImgWrapper>
				</a>
				<a
					href="https://firebasestorage.googleapis.com/v0/b/moline-3ac91.appspot.com/o/316147152_696457982101455_159606869136893980_n.jpg?alt=media&token=198d907a-334b-4639-9107-7411e15eadaf"
					data-lightbox="roadtrip"
				>
					<ImgWrapper>
						<ImgBox
							src="https://firebasestorage.googleapis.com/v0/b/moline-3ac91.appspot.com/o/316147152_696457982101455_159606869136893980_n.jpg?alt=media&token=198d907a-334b-4639-9107-7411e15eadaf"
							alt="Img"
						/>
					</ImgWrapper>
				</a>
				<a
					href="https://firebasestorage.googleapis.com/v0/b/moline-3ac91.appspot.com/o/313103026_820884559136531_3986496843699126462_n.jpg?alt=media&token=3afa4e55-e04d-4db4-a663-b37e2adb12c4"
					data-lightbox="roadtrip"
				>
					<ImgWrapper>
						<ImgBox
							src="https://firebasestorage.googleapis.com/v0/b/moline-3ac91.appspot.com/o/313103026_820884559136531_3986496843699126462_n.jpg?alt=media&token=3afa4e55-e04d-4db4-a663-b37e2adb12c4"
							alt="Img"
						/>
					</ImgWrapper>
				</a>
				<a
					href="https://firebasestorage.googleapis.com/v0/b/moline-3ac91.appspot.com/o/312345790_8170281293044335_8958449414368042678_n.jpg?alt=media&token=40b1d8e0-c82c-4461-a8d9-dadcb8f29437"
					data-lightbox="roadtrip"
				>
					<ImgWrapper>
						<ImgBox
							src="https://firebasestorage.googleapis.com/v0/b/moline-3ac91.appspot.com/o/312345790_8170281293044335_8958449414368042678_n.jpg?alt=media&token=40b1d8e0-c82c-4461-a8d9-dadcb8f29437"
							alt="Img"
						/>
					</ImgWrapper>
				</a>
				<a
					href="https://firebasestorage.googleapis.com/v0/b/moline-3ac91.appspot.com/o/316050246_2541718485969740_6444493062642029139_n.jpg?alt=media&token=9d9ee19a-281e-46a7-8675-bec6ba1881bd"
					data-lightbox="roadtrip"
				>
					<ImgWrapper>
						<ImgBox
							src="https://firebasestorage.googleapis.com/v0/b/moline-3ac91.appspot.com/o/316050246_2541718485969740_6444493062642029139_n.jpg?alt=media&token=9d9ee19a-281e-46a7-8675-bec6ba1881bd"
							alt="Img"
						/>
					</ImgWrapper>
				</a>
				<a
					href="https://firebasestorage.googleapis.com/v0/b/moline-3ac91.appspot.com/o/316173984_656731179429298_6233703948190180148_n.jpg?alt=media&token=b2531cbd-d3b7-440a-b0d5-0aee189f18d2"
					data-lightbox="roadtrip"
				>
					<ImgWrapper>
						<ImgBox
							src="https://firebasestorage.googleapis.com/v0/b/moline-3ac91.appspot.com/o/316173984_656731179429298_6233703948190180148_n.jpg?alt=media&token=b2531cbd-d3b7-440a-b0d5-0aee189f18d2"
							alt="Img"
						/>
					</ImgWrapper>
				</a>
				<a
					href="https://firebasestorage.googleapis.com/v0/b/moline-3ac91.appspot.com/o/316397316_1099342174114353_3092702400419851217_n.jpg?alt=media&token=89f1db52-ab9f-4c41-9700-a8f33cb6c9d8"
					data-lightbox="roadtrip"
				>
					<ImgWrapper>
						<ImgBox
							src="https://firebasestorage.googleapis.com/v0/b/moline-3ac91.appspot.com/o/316397316_1099342174114353_3092702400419851217_n.jpg?alt=media&token=89f1db52-ab9f-4c41-9700-a8f33cb6c9d8"
							alt="Img"
						/>
					</ImgWrapper>
				</a>
				<a
					href="https://firebasestorage.googleapis.com/v0/b/moline-3ac91.appspot.com/o/312310381_2207992829361293_182639341158560622_n.jpg?alt=media&token=af2c7a0c-306d-439f-a61a-f251f22eb586"
					data-lightbox="roadtrip"
				>
					<ImgWrapper>
						<ImgBox
							src="https://firebasestorage.googleapis.com/v0/b/moline-3ac91.appspot.com/o/312310381_2207992829361293_182639341158560622_n.jpg?alt=media&token=af2c7a0c-306d-439f-a61a-f251f22eb586"
							alt="Img"
						/>
					</ImgWrapper>
				</a>
				<a
					href="https://firebasestorage.googleapis.com/v0/b/moline-3ac91.appspot.com/o/316054627_399952335595242_2412572882061673178_n.jpg?alt=media&token=d5c0c500-3b5a-4b8d-87f2-8cf7518644ef"
					data-lightbox="roadtrip"
				>
					<ImgWrapper>
						<ImgBox
							src="https://firebasestorage.googleapis.com/v0/b/moline-3ac91.appspot.com/o/316054627_399952335595242_2412572882061673178_n.jpg?alt=media&token=d5c0c500-3b5a-4b8d-87f2-8cf7518644ef"
							alt="Img"
						/>
					</ImgWrapper>
				</a>
				<a
					href="https://firebasestorage.googleapis.com/v0/b/moline-3ac91.appspot.com/o/316129830_452497463702283_8388404124712223599_n.jpg?alt=media&token=b97ad73a-d8df-4910-8403-24aa4032a1ff"
					data-lightbox="roadtrip"
				>
					<ImgWrapper>
						<ImgBox
							src="https://firebasestorage.googleapis.com/v0/b/moline-3ac91.appspot.com/o/316129830_452497463702283_8388404124712223599_n.jpg?alt=media&token=b97ad73a-d8df-4910-8403-24aa4032a1ff"
							alt="Img"
						/>
					</ImgWrapper>
				</a>
				<a
					href="https://firebasestorage.googleapis.com/v0/b/moline-3ac91.appspot.com/o/317107699_684670743101836_2002295838937855775_n.jpg?alt=media&token=fd62aad0-78eb-4ce3-8c35-2169393ef01a"
					data-lightbox="roadtrip"
				>
					<ImgWrapper>
						<ImgBox
							src="https://firebasestorage.googleapis.com/v0/b/moline-3ac91.appspot.com/o/317107699_684670743101836_2002295838937855775_n.jpg?alt=media&token=fd62aad0-78eb-4ce3-8c35-2169393ef01a"
							alt="Img"
						/>
					</ImgWrapper>
				</a>
				<a
					href="https://firebasestorage.googleapis.com/v0/b/moline-3ac91.appspot.com/o/318289599_670924598091725_6108355833227129918_n.jpg?alt=media&token=77212d69-5f7f-4db1-9c81-9066f44a128d"
					data-lightbox="roadtrip"
				>
					<ImgWrapper>
						<ImgBox
							src="https://firebasestorage.googleapis.com/v0/b/moline-3ac91.appspot.com/o/318289599_670924598091725_6108355833227129918_n.jpg?alt=media&token=77212d69-5f7f-4db1-9c81-9066f44a128d"
							alt="Img"
						/>
					</ImgWrapper>
				</a>
				<a
					href="https://firebasestorage.googleapis.com/v0/b/moline-3ac91.appspot.com/o/317268436_1581395035660803_2805368497263103327_n.jpg?alt=media&token=c470de0f-3109-4f47-a1a4-df4dc57a9eeb"
					data-lightbox="roadtrip"
				>
					<ImgWrapper>
						<ImgBox
							src="https://firebasestorage.googleapis.com/v0/b/moline-3ac91.appspot.com/o/317268436_1581395035660803_2805368497263103327_n.jpg?alt=media&token=c470de0f-3109-4f47-a1a4-df4dc57a9eeb"
							alt="Img"
						/>
					</ImgWrapper>
				</a>
				<a
					href="https://firebasestorage.googleapis.com/v0/b/moline-3ac91.appspot.com/o/318300334_1792678124424453_6442419150119959299_n.jpg?alt=media&token=99f21548-c541-4f2b-a225-468daafdf565"
					data-lightbox="roadtrip"
				>
					<ImgWrapper>
						<ImgBox
							src="https://firebasestorage.googleapis.com/v0/b/moline-3ac91.appspot.com/o/318300334_1792678124424453_6442419150119959299_n.jpg?alt=media&token=99f21548-c541-4f2b-a225-468daafdf565"
							alt="Img"
						/>
					</ImgWrapper>
				</a>
				<a
					href="https://firebasestorage.googleapis.com/v0/b/moline-3ac91.appspot.com/o/317908225_1560711414350441_2230710796978651523_n.jpg?alt=media&token=f27f4dbd-b150-4e8d-93a8-cec34c3b692b"
					data-lightbox="roadtrip"
				>
					<ImgWrapper>
						<ImgBox
							src="https://firebasestorage.googleapis.com/v0/b/moline-3ac91.appspot.com/o/317908225_1560711414350441_2230710796978651523_n.jpg?alt=media&token=f27f4dbd-b150-4e8d-93a8-cec34c3b692b"
							alt="Img"
						/>
					</ImgWrapper>
				</a>
				<a
					href="https://firebasestorage.googleapis.com/v0/b/moline-3ac91.appspot.com/o/318654980_636324121511616_8708356982186496627_n.jpg?alt=media&token=bcaecc68-1eaa-4a3b-b1df-50f35d23f849"
					data-lightbox="roadtrip"
				>
					<ImgWrapper>
						<ImgBox
							src="https://firebasestorage.googleapis.com/v0/b/moline-3ac91.appspot.com/o/318654980_636324121511616_8708356982186496627_n.jpg?alt=media&token=bcaecc68-1eaa-4a3b-b1df-50f35d23f849"
							alt="Img"
						/>
					</ImgWrapper>
				</a>
				<a
					href="https://firebasestorage.googleapis.com/v0/b/moline-3ac91.appspot.com/o/317520377_2338006726368749_1203980507874374865_n.jpg?alt=media&token=366e36bb-199e-4897-a039-c6837387a4eb"
					data-lightbox="roadtrip"
				>
					<ImgWrapper>
						<ImgBox
							src="https://firebasestorage.googleapis.com/v0/b/moline-3ac91.appspot.com/o/317520377_2338006726368749_1203980507874374865_n.jpg?alt=media&token=366e36bb-199e-4897-a039-c6837387a4eb"
							alt="Img"
						/>
					</ImgWrapper>
				</a>
				<a
					href="https://firebasestorage.googleapis.com/v0/b/moline-3ac91.appspot.com/o/317366127_1132369690972852_964917313286497449_n.jpg?alt=media&token=306f3e37-efa0-4515-b3ac-3fbb1ff3534d"
					data-lightbox="roadtrip"
				>
					<ImgWrapper>
						<ImgBox
							src="https://firebasestorage.googleapis.com/v0/b/moline-3ac91.appspot.com/o/317366127_1132369690972852_964917313286497449_n.jpg?alt=media&token=306f3e37-efa0-4515-b3ac-3fbb1ff3534d"
							alt="Img"
						/>
					</ImgWrapper>
				</a>
				<a
					href="https://firebasestorage.googleapis.com/v0/b/moline-3ac91.appspot.com/o/317376990_1827344961017754_2159013044124442480_n.jpg?alt=media&token=3a4a6310-3f86-4bee-b0e8-5caca6840f9c"
					data-lightbox="roadtrip"
				>
					<ImgWrapper>
						<ImgBox
							src="https://firebasestorage.googleapis.com/v0/b/moline-3ac91.appspot.com/o/317376990_1827344961017754_2159013044124442480_n.jpg?alt=media&token=3a4a6310-3f86-4bee-b0e8-5caca6840f9c"
							alt="Img"
						/>
					</ImgWrapper>
				</a>
				<a
					href="https://firebasestorage.googleapis.com/v0/b/moline-3ac91.appspot.com/o/317144928_1190660231832297_5566449049605299852_n.jpg?alt=media&token=7608babf-7b41-4572-9375-96c1ab776047"
					data-lightbox="roadtrip"
				>
					<ImgWrapper>
						<ImgBox
							src="https://firebasestorage.googleapis.com/v0/b/moline-3ac91.appspot.com/o/317144928_1190660231832297_5566449049605299852_n.jpg?alt=media&token=7608babf-7b41-4572-9375-96c1ab776047"
							alt="Img"
						/>
					</ImgWrapper>
				</a>
				<a
					href="https://firebasestorage.googleapis.com/v0/b/moline-3ac91.appspot.com/o/317259991_871156194233537_2916389399132108458_n.jpg?alt=media&token=bc5e6f03-d799-4c10-a793-f341d3d0e081"
					data-lightbox="roadtrip"
				>
					<ImgWrapper>
						<ImgBox
							src="https://firebasestorage.googleapis.com/v0/b/moline-3ac91.appspot.com/o/317259991_871156194233537_2916389399132108458_n.jpg?alt=media&token=bc5e6f03-d799-4c10-a793-f341d3d0e081"
							alt="Img"
						/>
					</ImgWrapper>
				</a>
				<a
					href="https://firebasestorage.googleapis.com/v0/b/moline-3ac91.appspot.com/o/317144928_1190660231832297_5566449049605299852_n.jpg?alt=media&token=7608babf-7b41-4572-9375-96c1ab776047"
					data-lightbox="roadtrip"
				>
					<ImgWrapper>
						<ImgBox
							src="https://firebasestorage.googleapis.com/v0/b/moline-3ac91.appspot.com/o/317144928_1190660231832297_5566449049605299852_n.jpg?alt=media&token=7608babf-7b41-4572-9375-96c1ab776047"
							alt="Img"
						/>
					</ImgWrapper>
				</a>
				<a
					href="https://firebasestorage.googleapis.com/v0/b/moline-3ac91.appspot.com/o/317334256_982650592694497_9177797778186707040_n.jpg?alt=media&token=8e29b3bc-2405-4b3d-af1f-c2576d8406ab"
					data-lightbox="roadtrip"
				>
					<ImgWrapper>
						<ImgBox
							src="https://firebasestorage.googleapis.com/v0/b/moline-3ac91.appspot.com/o/317334256_982650592694497_9177797778186707040_n.jpg?alt=media&token=8e29b3bc-2405-4b3d-af1f-c2576d8406ab"
							alt="Img"
						/>
					</ImgWrapper>
				</a>
				<a
					href="https://firebasestorage.googleapis.com/v0/b/moline-3ac91.appspot.com/o/318186252_852098059438012_7239687138289831694_n.jpg?alt=media&token=27d6eb9d-84f1-4a76-b59d-1b4ebb1fed4e"
					data-lightbox="roadtrip"
				>
					<ImgWrapper>
						<ImgBox
							src="https://firebasestorage.googleapis.com/v0/b/moline-3ac91.appspot.com/o/318186252_852098059438012_7239687138289831694_n.jpg?alt=media&token=27d6eb9d-84f1-4a76-b59d-1b4ebb1fed4e"
							alt="Img"
						/>
					</ImgWrapper>
				</a>
				<a
					href="https://firebasestorage.googleapis.com/v0/b/moline-3ac91.appspot.com/o/317214605_2646189572197795_3612139148087890716_n.jpg?alt=media&token=ec511f51-7329-4fe9-a632-372adcacad98"
					data-lightbox="roadtrip"
				>
					<ImgWrapper>
						<ImgBox
							src="https://firebasestorage.googleapis.com/v0/b/moline-3ac91.appspot.com/o/317214605_2646189572197795_3612139148087890716_n.jpg?alt=media&token=ec511f51-7329-4fe9-a632-372adcacad98"
							alt="Img"
						/>
					</ImgWrapper>
				</a>{' '}
				<a
					href="https://firebasestorage.googleapis.com/v0/b/moline-3ac91.appspot.com/o/317159674_875660216798290_3859955578723720871_n.jpg?alt=media&token=12cd9701-1478-49f8-a99c-fa3a171f76af"
					data-lightbox="roadtrip"
				>
					<ImgWrapper>
						<ImgBox
							src="https://firebasestorage.googleapis.com/v0/b/moline-3ac91.appspot.com/o/317159674_875660216798290_3859955578723720871_n.jpg?alt=media&token=12cd9701-1478-49f8-a99c-fa3a171f76af"
							alt="Img"
						/>
					</ImgWrapper>
				</a>
				<a
					href="https://firebasestorage.googleapis.com/v0/b/moline-3ac91.appspot.com/o/317757867_875219326816737_2632035775162297552_n.jpg?alt=media&token=6f41c2e1-5382-4f35-b2d4-a9b0e882649b"
					data-lightbox="roadtrip"
				>
					<ImgWrapper>
						<ImgBox
							src="https://firebasestorage.googleapis.com/v0/b/moline-3ac91.appspot.com/o/317757867_875219326816737_2632035775162297552_n.jpg?alt=media&token=6f41c2e1-5382-4f35-b2d4-a9b0e882649b"
							alt="Img"
						/>
					</ImgWrapper>
				</a>
				<a
					href="https://firebasestorage.googleapis.com/v0/b/moline-3ac91.appspot.com/o/318522210_5718594608220914_5301840438622047544_n.jpg?alt=media&token=bd7c5c5d-75b5-4b6d-95fd-71d34c12ed8c"
					data-lightbox="roadtrip"
				>
					<ImgWrapper>
						<ImgBox
							src="https://firebasestorage.googleapis.com/v0/b/moline-3ac91.appspot.com/o/318522210_5718594608220914_5301840438622047544_n.jpg?alt=media&token=bd7c5c5d-75b5-4b6d-95fd-71d34c12ed8c"
							alt="Img"
						/>
					</ImgWrapper>
				</a>
				<a
					href="https://firebasestorage.googleapis.com/v0/b/moline-3ac91.appspot.com/o/317265703_676101910673284_5314982075899022842_n.jpg?alt=media&token=006d5a41-3bd7-4631-980a-e0e4858f808b"
					data-lightbox="roadtrip"
				>
					<ImgWrapper>
						<ImgBox
							src="https://firebasestorage.googleapis.com/v0/b/moline-3ac91.appspot.com/o/317265703_676101910673284_5314982075899022842_n.jpg?alt=media&token=006d5a41-3bd7-4631-980a-e0e4858f808b"
							alt="Img"
						/>
					</ImgWrapper>
				</a>
				<a
					href="https://firebasestorage.googleapis.com/v0/b/moline-3ac91.appspot.com/o/317200200_5826263014061299_436950940653087874_n.jpg?alt=media&token=9f76d3ab-9ad8-4cc1-886b-c4e20f5c5d87"
					data-lightbox="roadtrip"
				>
					<ImgWrapper>
						<ImgBox
							src="https://firebasestorage.googleapis.com/v0/b/moline-3ac91.appspot.com/o/317200200_5826263014061299_436950940653087874_n.jpg?alt=media&token=9f76d3ab-9ad8-4cc1-886b-c4e20f5c5d87"
							alt="Img"
						/>
					</ImgWrapper>
				</a>
				<a
					href="https://firebasestorage.googleapis.com/v0/b/moline-3ac91.appspot.com/o/317603364_839579000492593_3025057290902154424_n.jpg?alt=media&token=b37d951b-cfba-4965-835f-143643ba3567"
					data-lightbox="roadtrip"
				>
					<ImgWrapper>
						<ImgBox
							src="https://firebasestorage.googleapis.com/v0/b/moline-3ac91.appspot.com/o/317603364_839579000492593_3025057290902154424_n.jpg?alt=media&token=b37d951b-cfba-4965-835f-143643ba3567"
							alt="Img"
						/>
					</ImgWrapper>
				</a>
				<a
					href="https://firebasestorage.googleapis.com/v0/b/moline-3ac91.appspot.com/o/317107699_684670743101836_2002295838937855775_n.jpg?alt=media&token=fd62aad0-78eb-4ce3-8c35-2169393ef01a"
					data-lightbox="roadtrip"
				>
					<ImgWrapper>
						<ImgBox
							src="https://firebasestorage.googleapis.com/v0/b/moline-3ac91.appspot.com/o/317107699_684670743101836_2002295838937855775_n.jpg?alt=media&token=fd62aad0-78eb-4ce3-8c35-2169393ef01a"
							alt="Img"
						/>
					</ImgWrapper>
				</a>
				<a
					href="https://firebasestorage.googleapis.com/v0/b/moline-3ac91.appspot.com/o/317144928_1888935194783661_5836328747506174405_n.jpg?alt=media&token=ce85af97-1328-4c93-bc0f-18c1883a3e1f"
					data-lightbox="roadtrip"
				>
					<ImgWrapper>
						<ImgBox
							src="https://firebasestorage.googleapis.com/v0/b/moline-3ac91.appspot.com/o/317144928_1888935194783661_5836328747506174405_n.jpg?alt=media&token=ce85af97-1328-4c93-bc0f-18c1883a3e1f"
							alt="Img"
						/>
					</ImgWrapper>
				</a>
				<a
					href="https://firebasestorage.googleapis.com/v0/b/moline-3ac91.appspot.com/o/318166052_923127348655777_4821610306251054918_n.jpg?alt=media&token=968a577f-51ab-4776-bd44-1235640a5dce"
					data-lightbox="roadtrip"
				>
					<ImgWrapper>
						<ImgBox
							src="https://firebasestorage.googleapis.com/v0/b/moline-3ac91.appspot.com/o/318166052_923127348655777_4821610306251054918_n.jpg?alt=media&token=968a577f-51ab-4776-bd44-1235640a5dce"
							alt="Img"
						/>
					</ImgWrapper>
				</a>
				<a
					href="https://firebasestorage.googleapis.com/v0/b/moline-3ac91.appspot.com/o/317206128_5514870358630495_6133001921858410579_n.jpg?alt=media&token=d6a41a59-93b2-4826-88d5-6ebd5f91317d"
					data-lightbox="roadtrip"
				>
					<ImgWrapper>
						<ImgBox
							src="https://firebasestorage.googleapis.com/v0/b/moline-3ac91.appspot.com/o/317206128_5514870358630495_6133001921858410579_n.jpg?alt=media&token=d6a41a59-93b2-4826-88d5-6ebd5f91317d"
							alt="Img"
						/>
					</ImgWrapper>
				</a>
				<a
					href="https://firebasestorage.googleapis.com/v0/b/moline-3ac91.appspot.com/o/317605092_1563587044071830_1984450136708400227_n.jpg?alt=media&token=189785e2-53c1-48d8-a683-e418a56c88df"
					data-lightbox="roadtrip"
				>
					<ImgWrapper>
						<ImgBox
							src="https://firebasestorage.googleapis.com/v0/b/moline-3ac91.appspot.com/o/317605092_1563587044071830_1984450136708400227_n.jpg?alt=media&token=189785e2-53c1-48d8-a683-e418a56c88df"
							alt="Img"
						/>
					</ImgWrapper>
				</a>
				<a
					href="https://firebasestorage.googleapis.com/v0/b/moline-3ac91.appspot.com/o/317828687_2015789002145655_5978036431310402732_n.jpg?alt=media&token=7caec5b8-77b9-44e9-bb9f-fd1108f1e22e"
					data-lightbox="roadtrip"
				>
					<ImgWrapper>
						<ImgBox
							src="https://firebasestorage.googleapis.com/v0/b/moline-3ac91.appspot.com/o/317828687_2015789002145655_5978036431310402732_n.jpg?alt=media&token=7caec5b8-77b9-44e9-bb9f-fd1108f1e22e"
							alt="Img"
						/>
					</ImgWrapper>
				</a>
				<a
					href="https://firebasestorage.googleapis.com/v0/b/moline-3ac91.appspot.com/o/318580986_529967649041592_8052868182582038564_n.jpg?alt=media&token=652222eb-5f51-49f7-a72a-60a8cb3916d4"
					data-lightbox="roadtrip"
				>
					<ImgWrapper>
						<ImgBox
							src="https://firebasestorage.googleapis.com/v0/b/moline-3ac91.appspot.com/o/318580986_529967649041592_8052868182582038564_n.jpg?alt=media&token=652222eb-5f51-49f7-a72a-60a8cb3916d4"
							alt="Img"
						/>
					</ImgWrapper>
				</a>
				<a
					href="https://firebasestorage.googleapis.com/v0/b/moline-3ac91.appspot.com/o/317613069_1189294118462245_1552708150056014450_n.jpg?alt=media&token=06451f9b-b889-4149-b435-cac5ddb76e31"
					data-lightbox="roadtrip"
				>
					<ImgWrapper>
						<ImgBox
							src="https://firebasestorage.googleapis.com/v0/b/moline-3ac91.appspot.com/o/317613069_1189294118462245_1552708150056014450_n.jpg?alt=media&token=06451f9b-b889-4149-b435-cac5ddb76e31"
							alt="Img"
						/>
					</ImgWrapper>
				</a>
				<a
					href="https://firebasestorage.googleapis.com/v0/b/moline-3ac91.appspot.com/o/318967722_1327784521328785_8877942687260517050_n.jpg?alt=media&token=481d17cb-c95d-4787-8596-a80f15cd9f59"
					data-lightbox="roadtrip"
				>
					<ImgWrapper>
						<ImgBox
							src="https://firebasestorage.googleapis.com/v0/b/moline-3ac91.appspot.com/o/318967722_1327784521328785_8877942687260517050_n.jpg?alt=media&token=481d17cb-c95d-4787-8596-a80f15cd9f59"
							alt="Img"
						/>
					</ImgWrapper>
				</a>
				<a
					href="https://firebasestorage.googleapis.com/v0/b/moline-3ac91.appspot.com/o/317281508_1880220318983729_1078444625538221052_n.jpg?alt=media&token=7daaf753-12a8-49fe-9e1a-5eadff34e4ba"
					data-lightbox="roadtrip"
				>
					<ImgWrapper>
						<ImgBox
							src="https://firebasestorage.googleapis.com/v0/b/moline-3ac91.appspot.com/o/317281508_1880220318983729_1078444625538221052_n.jpg?alt=media&token=7daaf753-12a8-49fe-9e1a-5eadff34e4ba"
							alt="Img"
						/>
					</ImgWrapper>
				</a>
				<a
					href="https://firebasestorage.googleapis.com/v0/b/moline-3ac91.appspot.com/o/317373608_610135024217806_3199723231182716203_n.jpg?alt=media&token=019555e5-bd7d-4128-9fee-3a6f44f177b0"
					data-lightbox="roadtrip"
				>
					<ImgWrapper>
						<ImgBox
							src="https://firebasestorage.googleapis.com/v0/b/moline-3ac91.appspot.com/o/317373608_610135024217806_3199723231182716203_n.jpg?alt=media&token=019555e5-bd7d-4128-9fee-3a6f44f177b0"
							alt="Img"
						/>
					</ImgWrapper>
				</a>
				<a
					href="https://firebasestorage.googleapis.com/v0/b/moline-3ac91.appspot.com/o/317332605_673724901060848_1342673045586105623_n.jpg?alt=media&token=926d246d-e955-48b7-a5ca-be81a22e31f3"
					data-lightbox="roadtrip"
				>
					<ImgWrapper>
						<ImgBox
							src="https://firebasestorage.googleapis.com/v0/b/moline-3ac91.appspot.com/o/317332605_673724901060848_1342673045586105623_n.jpg?alt=media&token=926d246d-e955-48b7-a5ca-be81a22e31f3"
							alt="Img"
						/>
					</ImgWrapper>
				</a>
				<a
					href="https://firebasestorage.googleapis.com/v0/b/moline-3ac91.appspot.com/o/317522579_662889278613415_695349974985605340_n.jpg?alt=media&token=7e76bed6-8e27-40ee-9eb5-41bd489c4198"
					data-lightbox="roadtrip"
				>
					<ImgWrapper>
						<ImgBox
							src="https://firebasestorage.googleapis.com/v0/b/moline-3ac91.appspot.com/o/317522579_662889278613415_695349974985605340_n.jpg?alt=media&token=7e76bed6-8e27-40ee-9eb5-41bd489c4198"
							alt="Img"
						/>
					</ImgWrapper>
				</a>
				<a
					href="https://firebasestorage.googleapis.com/v0/b/moline-3ac91.appspot.com/o/317261484_3105392113038659_2508999047317416515_n.jpg?alt=media&token=43badda2-fcce-4696-a823-d136cb2168ab"
					data-lightbox="roadtrip"
				>
					<ImgWrapper>
						<ImgBox
							src="https://firebasestorage.googleapis.com/v0/b/moline-3ac91.appspot.com/o/317261484_3105392113038659_2508999047317416515_n.jpg?alt=media&token=43badda2-fcce-4696-a823-d136cb2168ab"
							alt="Img"
						/>
					</ImgWrapper>
				</a>
				<a
					href="https://firebasestorage.googleapis.com/v0/b/moline-3ac91.appspot.com/o/317462998_691769562540398_5292303159764268525_n.jpg?alt=media&token=8246b00c-0c0c-4ff4-9a08-efa179191479"
					data-lightbox="roadtrip"
				>
					<ImgWrapper>
						<ImgBox
							src="https://firebasestorage.googleapis.com/v0/b/moline-3ac91.appspot.com/o/317462998_691769562540398_5292303159764268525_n.jpg?alt=media&token=8246b00c-0c0c-4ff4-9a08-efa179191479"
							alt="Img"
						/>
					</ImgWrapper>
				</a>
			</Main>

			<Footer />
		</>
	);
};
const TitleImageWrapper = styled.div`
	position: relative;
	height: 300px;
`;

const ShadowEffect = styled.div`
	position: absolute;
	width: 100%;
	height: 100%;
	opacity: 0.55;
	background-color: #211f1f;
`;

const Img = styled.img`
	object-fit: cover;
	width: 100%;
	height: 100%;
`;

const ImgWrapper = styled.div`
	margin: 25px 0;
	display: flex;
	justify-content: center;
	width: 390px;
`;

const ImgBox = styled.img`
	width: 375px;
	height: 300px;
	object-fit: cover;
`;

const MainHomeHeader = styled.h1`
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translateX(-50%) translateY(-50%);
	text-transform: uppercase;
	margin: 0;
	padding: 0;
	font-size: 32px;
	font-weight: 900;
	color: #fcfcfc;
	letter-spacing: 2px;

	@media (max-width: 920px) {
		font-size: 28px;
	}
`;

const Main = styled.main`
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	width: 100%;

	padding: 60px 0;

	padding-bottom: 20px;

	@media (max-width: 1068px) {
		padding: 20px 0;
	}
`;

export default Gallery;
