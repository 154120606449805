import React, {useState} from 'react';
import styled from 'styled-components';
import {TextField} from '@mui/material';
import * as emailjs from '@emailjs/browser';

const Container = styled.footer`
	display: flex;
	justify-content: center;
	min-height: 80px;
	background-color: #9aaaac;
	padding: 50px 20px;

	@media (max-width: 1068px) {
		display: block;
	}
`;

const LeftSide = styled.section`
	max-width: 800px;
	border-radius: 10px;
	overflow: hidden;
	margin-right: 25px;

	@media (max-width: 1068px) {
		display: flex;
		justify-content: center;
		width: 100%;
		max-width: 100%;
		margin-top: 10px;
		margin-right: 0;
	}
`;

const RightSide = styled.section`
	height: 100%;
	width: 450px;
	margin-left: 25px;
	border-radius: 10px;

	@media (max-width: 1068px) {
		width: 100%;

		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		margin-top: 10px;
		margin-left: 0;
	}
`;

const Form = styled.form`
	padding: 10px 20px;
	background-color: #fcfcfc;
	border-radius: 10px;

	@media (max-width: 1068px) {
		margin-top: 10px;
	}
`;

const FormHeader = styled.h3`
	font-size: 22px;
	margin: 6px 0;
`;

const InformationText = styled.p`
	font-size: 16px;
`;

const StartCooperation = styled.button`
	text-transform: uppercase;
	font-size: 18px;
	font-weight: 500;
	line-height: 24px;
	text-decoration: none;
	padding: 12px 16px;
	border: 1px solid #6ea59a;
	background-color: #6ea59a;
	border-radius: 10px;
	cursor: pointer;
	margin-top: 10px;
	transition: all 0.4s;
	color: #fcfcfc;

	&:hover {
		color: #6ea59a;
		background-color: inherit;
	}

	@media (max-width: 920px) {
		font-size: 16px;
	}
`;

const ErrorMessage = styled.p`
	color: red;
`;

const SuccessMessage = styled.p`
	color: forestgreen;
`;

const ContactWrapper = styled.section`
	display: flex;
	flex-wrap: wrap;

	width: 100%;
`;

const InformationSection = styled.section`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 50%;

	margin-top: 16px;

	@media (max-width: 576px) {
		width: 100%;
	}
`;

const Information = styled.p`
	font-size: 16px;
	font-weight: 500;
	width: calc(100% - 80px);
`;

const InformationLink = styled.a`
	font-size: 16px;
	font-weight: 500;
	width: calc(100% - 80px);
	color: inherit;
	text-decoration: underline;
`;

const IconWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #fcfcfc;
	width: 60px;
	height: 60px;
	border-radius: 100%;

	margin-right: 8px;
`;

const Icon = styled.i`
	font-size: 32px;
	color: #6ea59a;
`;

const Footer = () => {
	const [name, setName] = useState<string>('');
	const [email, setEmail] = useState<string>('');
	const [message, setMessage] = useState<string>('');
	const [error, setError] = useState(false);
	const [success, setSuccess] = useState(false);

	return (
		<Container id="footer">
			<LeftSide>
				<div className="mapouter">
					<div className="gmap_canvas">
						<iframe
							id="gmap_canvas"
							title="map"
							width={window.innerWidth < 460 ? 400 : window.innerWidth > 760 ? 800 : 600}
							height={window.innerWidth < 460 ? 400 : window.innerWidth > 760 ? 550 : 500}
							src="https://maps.google.com/maps?q=Kuku%C4%8Dinov%C3%A1%20815%20Trebi%C5%A1ov&t=&z=15&ie=UTF8&iwloc=&output=embed"
							frameBorder="0"
							scrolling="no"
						/>
					</div>
				</div>
			</LeftSide>
			<RightSide>
				<Form>
					<FormHeader>Máte záujem o spoluprácu?</FormHeader>
					<InformationText>Nechajte nám správu a my sa Vám ozveme do 24 pracovných hodín.</InformationText>
					<TextField
						id="name"
						value={name}
						onChange={(e) => setName(e.target.value)}
						label="Meno"
						variant="filled"
						style={{width: '100%', margin: '0 0 10px'}}
					/>
					<TextField
						id="email"
						value={email}
						onChange={(e) => setEmail(e.target.value)}
						label="váš@email.sk"
						variant="filled"
						style={{width: '100%', margin: '0 0 10px'}}
					/>

					<TextField
						id="message"
						value={message}
						onChange={(e) => setMessage(e.target.value)}
						label="Sem napíšte obsah Vašej správy"
						variant="filled"
						multiline
						rows={3}
						style={{width: '100%', margin: '0 0 10px'}}
					/>

					{error && <ErrorMessage>Formulár musí byť celý vyplnený.</ErrorMessage>}
					{success && <SuccessMessage>Formulár bol odoslaný!</SuccessMessage>}

					<StartCooperation
						type="button"
						onClick={async () => {
							if (!name || !email || !message) {
								setError(true);
								setSuccess(false);
								return;
							}

							setError(false);

							try {
								const data = {
									service_id: 'service_okbvswn',
									template_id: 'template_9na65is',
									user_id: 'KeXItdWMQrmvlIdh3',
									template_params: {
										name,
										email,
										message,
										reply_to: email,
									},
								};

								await emailjs.send(
									data.service_id,
									data.template_id,
									data.template_params,
									data.user_id
								);

								setSuccess(true);
							} catch (e) {
								console.log(e);
							}

							setName('');
							setEmail('');
							setMessage('');
						}}
					>
						Odoslať
					</StartCooperation>
				</Form>

				<ContactWrapper>
					<InformationSection>
						<IconWrapper>
							<Icon className="fa-solid fa-building" />
						</IconWrapper>
						<Information>MO - LINE s.r.o.</Information>
					</InformationSection>

					<InformationSection>
						<IconWrapper>
							<Icon className="fa-solid fa-location-dot" />
						</IconWrapper>

						<Information>Kukučínova 875/17 07501 Trebišov</Information>
					</InformationSection>

					<InformationSection>
						<IconWrapper>
							<Icon className="fa-solid fa-phone" />
						</IconWrapper>

						<InformationLink href="tel:+421905571298">+421 944 059 077</InformationLink>
					</InformationSection>

					<InformationSection>
						<IconWrapper>
							<Icon className="fa-solid fa-envelope" />
						</IconWrapper>

						<InformationLink href="mailto:martin@moline.sk">martin@moline.sk</InformationLink>
					</InformationSection>
				</ContactWrapper>
			</RightSide>
		</Container>
	);
};

export default Footer;
