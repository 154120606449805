import React from 'react';
import Header from '../layout/Header';
import Footer from '../layout/Footer';
import styled from 'styled-components';
import t2 from './t2.jpeg';
import stockImg from './stock.jpeg';

const ServiceSection = styled.section`
	display: flex;
	max-width: 1200px;

	margin-bottom: 40px;

	@media (max-width: 1068px) {
		flex-wrap: wrap;
		margin-bottom: 0;
	}
`;

const LeftSide = styled.div`
	width: calc(50%);
	padding: 0 10px;

	@media (max-width: 1068px) {
		width: 100%;
		padding: 0;
		margin-right: 0;
	}
`;

const RightSide = styled.div`
	width: calc(50%);
	padding: 0 10px;

	@media (max-width: 1068px) {
		width: 100%;
		padding: 0;
		margin-left: 0;
	}
`;

const ServiceName = styled.h2`
	text-align: center;
`;

const ServiceText = styled.p`
	padding: 0 5px;
`;

const Services = () => {
	return (
		<>
			<Header />

			<TitleImageWrapper>
				<ShadowEffect />
				<Img
					src="https://firebasestorage.googleapis.com/v0/b/moline-3ac91.appspot.com/o/iStock-859916128%20(1).jpg?alt=media&token=edef5213-30b3-4bbb-bf88-3ce1f26f951c"
					alt="img"
				/>
				<MainHomeHeader>SLUŽBY</MainHomeHeader>
			</TitleImageWrapper>

			<Main>
				<ServiceSection>
					{window.innerWidth > 1068 && (
						<LeftSide>
							<Img
								src="https://firebasestorage.googleapis.com/v0/b/moline-3ac91.appspot.com/o/312345790_8170281293044335_8958449414368042678_n.jpg?alt=media&token=40b1d8e0-c82c-4461-a8d9-dadcb8f29437"
								alt="img"
							/>
						</LeftSide>
					)}
					<RightSide>
						<ServiceName>Štandardná preprava</ServiceName>
						<ServiceText>
							Šatndartnú kamiónovú prepravu momentálne zabezpečujeme vozidlami značky Scania s emisnou
							normou Euro 6, pri týchto vozidlách využívame plachtové návesy s trojstranou rolovacou
							plachtou. Ložná plocha : dĺžka 13,60 m, šírka 2,45 m, výška 2,75 m, Nosnosť 25,50 ton.
						</ServiceText>
					</RightSide>

					{window.innerWidth <= 1068 && (
						<LeftSide>
							<Img
								src="https://firebasestorage.googleapis.com/v0/b/moline-3ac91.appspot.com/o/312345790_8170281293044335_8958449414368042678_n.jpg?alt=media&token=40b1d8e0-c82c-4461-a8d9-dadcb8f29437"
								alt="img"
							/>
						</LeftSide>
					)}
				</ServiceSection>

				<ServiceSection>
					<LeftSide>
						<ServiceName>Veľkoobjemová preprava</ServiceName>
						<ServiceText>
							Od začiatku roku 2017 sme sa začali zaoberať aj prepravou veľkoobjemových tovarov. Pre túto
							prácu máme momentálne k dispozícií tri súpravy a to o objeme 100m3 a dvakrát o objeme 120
							m3.
						</ServiceText>
						<ul>
							<li>
								Ložná plocha 100 m3: dĺžka 13,60 m, šírka 2,45 m, výška 3,03 m, Nosnosť 25,50 ton, s pri
								zdvihom strechy.
							</li>
							<li>
								Ložná plocha 120 m3:
								<ul>
									<li>auto - 7,70 m x 2,50 m x 3,03 m s pri zdvihom strechy</li>
									<li>
										príves - 7,70 m x 2,50 m x 3,03 m s pri zdvihom strechy s nosnosťou 24,50 ton
									</li>
								</ul>
							</li>
						</ul>
					</LeftSide>
					<RightSide>
						<Img
							src="https://firebasestorage.googleapis.com/v0/b/moline-3ac91.appspot.com/o/316050246_2541718485969740_6444493062642029139_n.jpg?alt=media&token=9d9ee19a-281e-46a7-8675-bec6ba1881bd"
							alt="img"
						/>
					</RightSide>
				</ServiceSection>

				<ServiceSection>
					{window.innerWidth > 1068 && (
						<LeftSide>
							<Img
								src="https://firebasestorage.googleapis.com/v0/b/moline-3ac91.appspot.com/o/317376990_1827344961017754_2159013044124442480_n.jpg?alt=media&token=3a4a6310-3f86-4bee-b0e8-5caca6840f9c"
								alt="img"
							/>
						</LeftSide>
					)}
					<RightSide>
						<ServiceName>Nadrozmerná preprava</ServiceName>
						<ServiceText>
							Už v roku 2007 sme sa začali zaoberať prepravou nadrozmerných nákladov a môžeme povedať, že
							sme dokázali prepraviť viacero extrémnych prepráv v rámci EU.
						</ServiceText>

						<ServiceText>
							Dokázali sme prepraviť dĺžkové nadrozmery zo Slovenska do Bulharska o celkovej dĺžke 42
							metrov, o šírke 6,50 metra zo Slovenska do Francúzska a tak tiež aj 180 tonový náklad z
							Bratislavy do Českého Mělníka.
						</ServiceText>

						<ServiceText>
							Vďaka nášmu partnerovi z Poľska dokážeme zabezpečiť prepravy nadrozmerných nákladov aj mimo
							EU a to do Ruska, Kazachstanu, Uzbekistanu a iných krajín tretieho sveta.
						</ServiceText>
					</RightSide>

					{window.innerWidth <= 1068 && (
						<LeftSide>
							<Img src={t2} alt="img" />
						</LeftSide>
					)}
				</ServiceSection>

				<ServiceSection>
					<LeftSide>
						<ServiceName>Skladovacie priestory</ServiceName>
						<ServiceText>
							Disponujeme vlastnými skladovacími priestormi, ako aj vlastnými parkovacími priestormi.
							Sklady slúžia na krátkodobé aj dlhodobé skladovanie.
						</ServiceText>

						<ul>
							<li>Rozloha skladu je 500 m2 (50 m x 10 m).</li>
							<li>
								Sklad je zabezpečený kamerovým a bezpečnostným systémom.
								<ul>
									<li>Nepretržite dostupná obsluha skladu.</li>
									<li>
										Naloženie a vyloženie tovaru zabezpečia naftové vysokozdvižné vozíky značky
										Still RC-40.
									</li>
								</ul>
							</li>
						</ul>
					</LeftSide>
					<RightSide>
						<Img src={stockImg} alt="img" />
					</RightSide>
				</ServiceSection>
			</Main>

			<Footer />
		</>
	);
};

const TitleImageWrapper = styled.div`
	position: relative;
	height: 300px;
`;

const ShadowEffect = styled.div`
	position: absolute;
	width: 100%;
	height: 100%;
	opacity: 0.55;
	background-color: #211f1f;
`;

const Img = styled.img`
	object-fit: cover;
	width: 100%;
	height: 100%;
`;

const MainHomeHeader = styled.h1`
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translateX(-50%) translateY(-50%);
	text-transform: uppercase;
	margin: 0;
	padding: 0;
	font-size: 32px;
	font-weight: 900;
	color: #fcfcfc;
	letter-spacing: 2px;

	p {
		padding: 0 5px;
	}

	@media (max-width: 920px) {
		font-size: 28px;
	}
`;

const Main = styled.main`
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	width: 100%;

	padding: 60px 0;

	padding-bottom: 20px;

	@media (max-width: 1068px) {
		padding: 20px 0;
	}
`;

export default Services;
