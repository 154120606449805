import React from 'react';
import Header from '../layout/Header';
import Footer from '../layout/Footer';
import styled from 'styled-components';
import ImageGallery from 'react-image-gallery';

const images = [
	{
		original:
			'https://firebasestorage.googleapis.com/v0/b/moline-3ac91.appspot.com/o/313103026_820884559136531_3986496843699126462_n.jpg?alt=media&token=3afa4e55-e04d-4db4-a663-b37e2adb12c4',
		thumbnail:
			'https://firebasestorage.googleapis.com/v0/b/moline-3ac91.appspot.com/o/313103026_820884559136531_3986496843699126462_n.jpg?alt=media&token=3afa4e55-e04d-4db4-a663-b37e2adb12c4',
	},
	{
		original:
			'https://firebasestorage.googleapis.com/v0/b/moline-3ac91.appspot.com/o/315893483_674099304084296_3244177236350884666_n.jpg?alt=media&token=d36bb4ad-53a7-421d-ae55-f473d961411f',
		thumbnail:
			'https://firebasestorage.googleapis.com/v0/b/moline-3ac91.appspot.com/o/315893483_674099304084296_3244177236350884666_n.jpg?alt=media&token=d36bb4ad-53a7-421d-ae55-f473d961411f',
	},
	{
		original:
			'https://firebasestorage.googleapis.com/v0/b/moline-3ac91.appspot.com/o/316050246_2541718485969740_6444493062642029139_n.jpg?alt=media&token=9d9ee19a-281e-46a7-8675-bec6ba1881bd',
		thumbnail:
			'https://firebasestorage.googleapis.com/v0/b/moline-3ac91.appspot.com/o/316050246_2541718485969740_6444493062642029139_n.jpg?alt=media&token=9d9ee19a-281e-46a7-8675-bec6ba1881bd',
	},
	{
		original:
			'https://firebasestorage.googleapis.com/v0/b/moline-3ac91.appspot.com/o/316054627_399952335595242_2412572882061673178_n.jpg?alt=media&token=d5c0c500-3b5a-4b8d-87f2-8cf7518644ef',
		thumbnail:
			'https://firebasestorage.googleapis.com/v0/b/moline-3ac91.appspot.com/o/316054627_399952335595242_2412572882061673178_n.jpg?alt=media&token=d5c0c500-3b5a-4b8d-87f2-8cf7518644ef',
	},
	{
		original:
			'https://firebasestorage.googleapis.com/v0/b/moline-3ac91.appspot.com/o/316397316_1099342174114353_3092702400419851217_n.jpg?alt=media&token=89f1db52-ab9f-4c41-9700-a8f33cb6c9d8',
		thumbnail:
			'https://firebasestorage.googleapis.com/v0/b/moline-3ac91.appspot.com/o/316397316_1099342174114353_3092702400419851217_n.jpg?alt=media&token=89f1db52-ab9f-4c41-9700-a8f33cb6c9d8',
	},
];

const AboutUs = () => {
	return (
		<>
			<Header />

			<TitleImageWrapper>
				<ShadowEffect />
				<Img
					src="https://firebasestorage.googleapis.com/v0/b/moline-3ac91.appspot.com/o/iStock-859916128%20(1).jpg?alt=media&token=edef5213-30b3-4bbb-bf88-3ce1f26f951c"
					alt="img"
				/>
				<MainHomeHeader>O nás</MainHomeHeader>
			</TitleImageWrapper>

			<Main>
				<SectionWrapper>
					<p>
						História našej spoločnosti siaha až do roku 2001. V tejto dobe kúpil jeden zo súčasných
						riaditeľov prvé nákladné vozidlo značky Liaz 110. Neskôr k tomu pribudol aj vlek BSS. V roku
						2005 firma kúpila prvý ťahač značky Iveco Stralis. Koncom roka 2008 už firma mala vo vlastnom
						vozovom parku 8 návesových súprav. Z toho 5 značky Iveco, 2 značky Renault a jedno značky Scania
						. V období krízy firma predala 5 vozidiel a pokračovala vo svojej činnosti na ďalej. Do roku
						2012 naša firma mala vo svojom vozovom parku len dve vozidlá. V roku 2012 sa rozhodlo, že sa
						firma transformuje na spoločnosť s.r.o. Od roku 2012 spoločnosť vystupuje pod názvom MO LINE
						s.r.o., so sídlom v Trebišove. Naša spoločnosť sa zaoberá medzinárodnou kamiónovou dopravou v
						rámci celej EU.
					</p>
					<PhotosWrapper id="gallery">
						<ImageGallery items={images} autoPlay />
					</PhotosWrapper>
				</SectionWrapper>
			</Main>

			<Footer />
		</>
	);
};

const SectionWrapper = styled.section`
	width: 100%;
	max-width: 1200px;
`;

const TitleImageWrapper = styled.div`
	position: relative;
	height: 300px;
`;

const ShadowEffect = styled.div`
	position: absolute;
	width: 100%;
	height: 100%;
	opacity: 0.55;
	background-color: #211f1f;
`;

const Img = styled.img`
	object-fit: cover;
	width: 100%;
	height: 100%;
`;

const MainHomeHeader = styled.h1`
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translateX(-50%) translateY(-50%);
	text-transform: uppercase;
	margin: 0;
	padding: 0;
	font-size: 32px;
	font-weight: 900;
	color: #fcfcfc;
	letter-spacing: 2px;

	@media (max-width: 920px) {
		font-size: 28px;
	}
`;

const Main = styled.main`
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	width: 100%;

	padding: 30px 0;

	padding-bottom: 20px;

	p {
		padding: 0 5px;
	}

	@media (max-width: 1068px) {
		padding: 20px 0;
	}
`;

const PhotosWrapper = styled.div`
	display: flex;
	justify-content: center;

	width: 100%;

	.image-gallery {
		max-width: 100%;
	}
`;

export default AboutUs;
