import React from 'react';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import Home from './pages/Home';
import Services from './pages/Services';
import Gallery from './pages/Gallery';
import AboutUs from './pages/AboutUs';

function App() {
	return (
		<BrowserRouter>
			<Routes>
				<Route path="/services" element={<Services />} />
				<Route path="/gallery" element={<Gallery />} />
				<Route path="/about-us" element={<AboutUs />} />
				<Route path="/" element={<Home />} />
				<Route element={<Home />} />
			</Routes>
		</BrowserRouter>
	);
}

export default App;
