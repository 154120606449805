import React from 'react';
import styled from 'styled-components';
import {Link, useNavigate} from 'react-router-dom';

interface Props {
	icon: string;
	color: string;
	header: string;
	text: string;
	isBorder?: boolean;
	noMoreInfo?: boolean;
}

const CardBox: React.FC<Props> = ({icon, color, header, text, isBorder = false, noMoreInfo = false}) => {
	const navigate = useNavigate();

	return (
		<Wrapper color={color} isBorder={isBorder}>
			<IconWrapper onClick={() => navigate('/services')}>
				<i className={`fa-solid ${icon}`} />
			</IconWrapper>

			<Header onClick={() => navigate('/services')}>{header}</Header>
			<Text>{text}</Text>
			{!noMoreInfo && <MoreInfo to="/services">Viac informácii</MoreInfo>}
		</Wrapper>
	);
};

const Wrapper = styled.div<{isBorder: boolean}>`
	position: relative;
	width: 290px;
	padding: 20px 40px;
	margin: 0 15px 40px 15px;
	border-radius: 10px;

	background-color: ${({color, isBorder}) => !isBorder && color};
	color: ${({color, isBorder}) => (isBorder && color ? color : '#fcfcfc')};

	border: ${({color, isBorder}) => isBorder && `1px solid ${color}`};

	transition: top 0.4s;
	top: 0;

	&:hover {
		top: -10px;
	}

	@media (max-width: 1620px) {
		width: 450px;
	}

	@media (max-width: 1250px) {
		width: 360px;
	}

	@media (max-width: 960px) {
		width: 300px;

		margin-right: 10px;
		margin-left: 10px;
	}

	@media (max-width: 820px) {
		width: 370px;
	}

	@media (max-width: 468px) {
		width: 250px;
	}
`;

const IconWrapper = styled.div`
	width: 100%;
	text-align: center;
	cursor: pointer;

	.fa-solid {
		font-size: 32px;

		transition: 0s font-size, 0.2s linear;

		&:hover {
			font-size: 40px;
		}
	}
`;

const Header = styled.h4`
	width: 100%;
	font-size: 18px;
	font-weight: 900;
	letter-spacing: 1.5px;
	text-align: center;
	cursor: pointer;
`;

const Text = styled.p`
	min-height: 180px;
`;

const MoreInfo = styled(Link)`
	width: 100%;
	margin-top: 16px;
	text-decoration: none;
	color: #fcfcfc;
	border: 1px solid #fcfcfc;
	border-radius: 16px;
	padding: 8px 12px;

	&:hover {
		opacity: 0.8;
	}
`;

export default CardBox;
