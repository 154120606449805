import {createGlobalStyle} from 'styled-components';

export default createGlobalStyle`
  html, body {
	scroll-behavior: smooth;
  }
  
  body {
    color: #211f1f;
	background-color: #fcfcfc;
    font-family: Roboto, sans-serif;
  }
  
  p {
    line-height: 1.4em;
  }
`;
