import React from 'react';
import Header from '../layout/Header';
import styled from 'styled-components';
import eu from './eu.svg';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import CardBox from '../layout/CardBox';
import Footer from '../layout/Footer';

const TitleImageWrapper = styled.div`
	position: relative;
`;

const Img = styled.img`
	object-fit: cover;
	width: 100%;
	height: 600px;
`;

const ImgWithoutCover = styled.img`
	object-fit: cover;
	width: 100%;
	height: auto;
`;

const SmartTextWrapper = styled.div`
	position: absolute;
	top: 160px;
	left: 250px;

	@media (max-width: 1680px) {
		top: 120px;
		left: 200px;
	}

	@media (max-width: 1280px) {
		top: 100px;
		left: 130px;
	}

	@media (max-width: 920px) {
		top: 80px;
		left: 50px;
	}
`;

const MainHomeHeader = styled.h1`
	text-transform: uppercase;
	margin-bottom: 0;
	padding-bottom: 0;
	font-size: 32px;
	font-weight: 900;
	color: #fcfcfc;
	letter-spacing: 2px;

	@media (max-width: 920px) {
		font-size: 28px;
	}
`;

const Text = styled.p`
	text-transform: uppercase;
	margin: 4px 0 4px 50px;
	padding-bottom: 0;
	font-size: 18px;
	font-weight: 500;
	color: #fcfcfc;
	font-style: italic;
	line-height: 26px;
	letter-spacing: 1px;

	@media (max-width: 920px) {
		margin-left: 30px;
		font-size: 16px;
	}

	@media (max-width: 568px) {
		margin-left: 15px;
	}
`;

const StartCooperation = styled.a`
	text-transform: uppercase;
	font-size: 18px;
	font-weight: 500;
	color: #fcfcfc;
	font-style: italic;
	line-height: 60px;
	text-decoration: none;
	padding: 12px 16px;
	border: 1px solid #fcfcfc;
	border-radius: 10px;
	transition: all 0.4s;

	&:hover {
		background-color: #0c45b3;
	}

	@media (max-width: 920px) {
		font-size: 16px;
	}
`;

const Main = styled.main`
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	width: 100%;

	position: relative;
	top: -120px;
`;

const CardsWrapper = styled.section`
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
`;

const SectionWrapper = styled.section`
	width: 100%;
	max-width: 1300px;
	p {
		padding: 0 5px;
	}
`;

const SectionHeader = styled.h2`
	font-size: 28px;
	font-weight: 700;
	color: #211f1f;
	margin-top: 0;
	letter-spacing: 1.5px;
`;

const PhotosWrapper = styled.div`
	display: flex;
	justify-content: center;

	width: 100%;

	.image-gallery {
		max-width: 100%;
	}
`;

const BrandColorText = styled.strong`
	color: #0c45b3;
`;

const SectionSpace = styled.div<{noColor?: boolean}>`
	width: 100%;
	background-color: ${({noColor}) => !noColor && '#3f4137'};
	height: ${({noColor}) => (!noColor ? '50px' : '20px')};
	margin: 20px 0;
`;

const EuropeanUnionWrapper = styled.div`
	display: flex;
	justify-content: center;
	margin-bottom: 20px;

	padding: 0 150px;

	@media (max-width: 920px) {
		margin-left: 30px;
		font-size: 16px;

		padding: 0;
	}

	@media (max-width: 568px) {
		margin-left: 15px;
	}
`;

const images = [
	{
		original:
			'https://firebasestorage.googleapis.com/v0/b/moline-3ac91.appspot.com/o/313103026_820884559136531_3986496843699126462_n.jpg?alt=media&token=3afa4e55-e04d-4db4-a663-b37e2adb12c4',
		thumbnail:
			'https://firebasestorage.googleapis.com/v0/b/moline-3ac91.appspot.com/o/313103026_820884559136531_3986496843699126462_n.jpg?alt=media&token=3afa4e55-e04d-4db4-a663-b37e2adb12c4',
	},
	{
		original:
			'https://firebasestorage.googleapis.com/v0/b/moline-3ac91.appspot.com/o/315893483_674099304084296_3244177236350884666_n.jpg?alt=media&token=d36bb4ad-53a7-421d-ae55-f473d961411f',
		thumbnail:
			'https://firebasestorage.googleapis.com/v0/b/moline-3ac91.appspot.com/o/315893483_674099304084296_3244177236350884666_n.jpg?alt=media&token=d36bb4ad-53a7-421d-ae55-f473d961411f',
	},
	{
		original:
			'https://firebasestorage.googleapis.com/v0/b/moline-3ac91.appspot.com/o/316050246_2541718485969740_6444493062642029139_n.jpg?alt=media&token=9d9ee19a-281e-46a7-8675-bec6ba1881bd',
		thumbnail:
			'https://firebasestorage.googleapis.com/v0/b/moline-3ac91.appspot.com/o/316050246_2541718485969740_6444493062642029139_n.jpg?alt=media&token=9d9ee19a-281e-46a7-8675-bec6ba1881bd',
	},
	{
		original:
			'https://firebasestorage.googleapis.com/v0/b/moline-3ac91.appspot.com/o/316054627_399952335595242_2412572882061673178_n.jpg?alt=media&token=d5c0c500-3b5a-4b8d-87f2-8cf7518644ef',
		thumbnail:
			'https://firebasestorage.googleapis.com/v0/b/moline-3ac91.appspot.com/o/316054627_399952335595242_2412572882061673178_n.jpg?alt=media&token=d5c0c500-3b5a-4b8d-87f2-8cf7518644ef',
	},
	{
		original:
			'https://firebasestorage.googleapis.com/v0/b/moline-3ac91.appspot.com/o/316397316_1099342174114353_3092702400419851217_n.jpg?alt=media&token=89f1db52-ab9f-4c41-9700-a8f33cb6c9d8',
		thumbnail:
			'https://firebasestorage.googleapis.com/v0/b/moline-3ac91.appspot.com/o/316397316_1099342174114353_3092702400419851217_n.jpg?alt=media&token=89f1db52-ab9f-4c41-9700-a8f33cb6c9d8',
	},
];

const ShadowEffect = styled.div`
	position: absolute;
	width: 100%;
	height: 100%;
	opacity: 0.5;
	background-color: #211f1f;
`;

const SectionColorWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	background-color: rgba(110, 165, 154, 0.15);

	padding: 40px 0;
`;

const Home = () => {
	return (
		<>
			<Header />
			<TitleImageWrapper>
				<ShadowEffect />
				<Img
					src="https://firebasestorage.googleapis.com/v0/b/moline-3ac91.appspot.com/o/318522210_5718594608220914_5301840438622047544_n%20(1).jpg?alt=media&token=d9433102-2e93-4372-8de2-7f1a3762198e"
					alt="img"
				/>

				<SmartTextWrapper>
					<MainHomeHeader>VÁŠ PARTNER NA CESTACH</MainHomeHeader>
					<Text>Viac než 20 rokov v preprave</Text>
					<Text>Medzinárodné skúsenosti s prepravou tovarov</Text>
					<Text>Moderný vozový park</Text>
					<Text>
						<StartCooperation href="#footer">Začať spoluprácu</StartCooperation>
					</Text>
				</SmartTextWrapper>
			</TitleImageWrapper>

			<Main>
				<CardsWrapper>
					<CardBox
						icon="fa-truck"
						color="#6ea59a"
						header="Štandardná preprava"
						text="Šatndartnú kamiónovú prepravu momentálne zabezpečujeme vozidlami
značky Scania s emisnou normou Euro 6, pri týchto vozidlách využívame
plachtové návesy s trojstranou rolovacou plachtou.
Ložná plocha : dĺžka 13,60 m, šírka 2,45 m, výška 2,75 m, Nosnosť 25,50
ton"
					/>

					<CardBox
						icon="fa-truck-ramp-box"
						color="#DE6233"
						header="Veľkoobjemová preprava"
						text="Od začiatku roku 2017 sme sa začali zaoberať aj prepravou
veľkoobjemových tovarov. Pre túto prácu máme momentálne k
dispozícií tri súpravy a to o objeme 100m3 a dvakrát o objeme 120 m3."
					/>

					<CardBox
						icon="fa-truck-moving"
						color="#072C72"
						header="Nadrozmerná preprava"
						text="Už v roku 2007 sme sa začali zaoberať prepravou nadrozmerných
nákladov a môžeme povedať, že sme dokázali prepraviť viacero
extrémnych prepráv v rámci EU. Poskytujeme aj asistenčné služby spojené s prepravou nadrozmerného nákladu."
					/>

					<CardBox
						icon="fa-cubes"
						color="#c6afbf"
						header="Skladovacie priestory"
						text="Disponujeme vlastnými skladovacími priestormi o rozlohe 500 m2 (50 m x 10 m) ako aj vlastnými parkovacími priestormi. Sklady slúžia na krátkodobé aj dlhodobé skladovanie"
					/>
				</CardsWrapper>

				<SectionColorWrapper>
					<SectionWrapper>
						<SectionHeader>O nás</SectionHeader>
						<p>
							História našej spoločnosti siaha až do roku 2001. V tejto dobe kúpil jeden zo súčasných
							riaditeľov prvé nákladné vozidlo značky Liaz 110. Neskôr k tomu pribudol aj vlek BSS. V roku
							2005 firma kúpila prvý ťahač značky Iveco Stralis. Koncom roka 2008 už firma mala vo
							vlastnom vozovom parku 8 návesových súprav. Z toho 5 značky Iveco, 2 značky Renault a jedno
							značky Scania . V období krízy firma predala 5 vozidiel a pokračovala vo svojej činnosti na
							ďalej. Do roku 2012 naša firma mala vo svojom vozovom parku len dve vozidlá. V roku 2012 sa
							rozhodlo, že sa firma transformuje na spoločnosť s.r.o. Od roku 2019 spoločnosť vystupuje
							pod názvom MO LINE s.r.o., so sídlom v Trebišove. Naša spoločnosť sa zaoberá medzinárodnou
							kamiónovou dopravou v rámci celej EU.
						</p>
						<PhotosWrapper id="gallery">
							<ImageGallery items={images} autoPlay />
						</PhotosWrapper>
					</SectionWrapper>
				</SectionColorWrapper>

				<SectionSpace noColor />

				<SectionWrapper>
					<SectionHeader>
						Prečo si vybrať <BrandColorText>MO-LINE</BrandColorText>?
					</SectionHeader>

					<CardsWrapper>
						<CardBox
							icon="fa-house"
							color="#DE6233"
							header="Tradícia"
							text="V oblasti medzinárodnej kamiónovej dopravy, špedície a logistiky pôsobíme už od roku 2001. Od roku 2019 pôsobíme pod názvom MO LINE s.r.o. a sme stabilným hráčom na slovenskom, ale aj európskom trhu."
							isBorder
							noMoreInfo
						/>

						<CardBox
							icon="fa-check"
							color="#6ea59a"
							header="Flexibilita & spokojnosť"
							text="Disponujeme vozovým parko značky Volvo, Scania, Mercedes a Iveco s technickým vybavením na vysokej úrovni. Zákazky vieme vyriešiť expresne rýchlo a k najväčšej spokojnosti klienta. Vďaka sledovacím systémom zaručujeme bezpečnosť vašej zásielky a informáciu o jej polohe."
							isBorder
							noMoreInfo
						/>

						<CardBox
							icon="fa-truck"
							color="#072C72"
							header="Špecializácia"
							text="Špecializujeme sa na medzinárodnú aj vnútroštátnu špedíciu a prepravu vlastnými vozidlami s hlavným zameraním na oblasť západnej Európy."
							isBorder
							noMoreInfo
						/>
					</CardsWrapper>
				</SectionWrapper>

				<SectionWrapper>
					<SectionHeader>Váš tovar prepravíme do celej Európy</SectionHeader>
					<EuropeanUnionWrapper>
						<ImgWithoutCover src={eu} alt="EU" />
					</EuropeanUnionWrapper>
				</SectionWrapper>
			</Main>

			<Footer />
		</>
	);
};

export default Home;
