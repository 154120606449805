import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {Link, useNavigate} from 'react-router-dom';

import logo from './logo.png';

const Header = () => {
	const navigate = useNavigate();
	const [isShownHeaderMenu, setIsShownHeaderMenu] = useState(false);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<Container>
			<LogoWrapper>
				<Logo src={logo} alt="MO - LINE logo" onClick={() => navigate('/')} />
			</LogoWrapper>

			<LinksWrapper>
				{window.innerWidth > 576 && (
					<>
						<Name to="/about-us">O nás</Name>
						<Name to="/services">Služby</Name>
						<Name to="/gallery">Galéria</Name>
						<SpecialName data-scroll href="#footer">
							Napíšte nám
						</SpecialName>
					</>
				)}

				{window.innerWidth <= 576 && (
					<HamburgerIcon
						className="fa-solid fa-bars"
						onClick={() => setIsShownHeaderMenu(!isShownHeaderMenu)}
					/>
				)}
			</LinksWrapper>

			{isShownHeaderMenu && (
				<HeaderRollMenu onClick={() => setIsShownHeaderMenu(!isShownHeaderMenu)}>
					<HeaderRollItem>
						<Name to="/about-us">O nás</Name>
					</HeaderRollItem>

					<HeaderRollItem>
						<Name to="/services">Služby</Name>
					</HeaderRollItem>

					<HeaderRollItem>
						<Name to="/gallery">Galéria</Name>
					</HeaderRollItem>

					<HeaderRollItem>
						<SpecialName data-scroll href="#footer">
							Napíšte nám
						</SpecialName>
					</HeaderRollItem>
				</HeaderRollMenu>
			)}
		</Container>
	);
};

const HeaderRollMenu = styled.div`
	position: absolute;
	top: 80px;
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	background: rgb(255, 191, 0);
	background: linear-gradient(
		90deg,
		rgba(255, 191, 0, 0.8295693277310925) 12%,
		rgba(255, 191, 0, 0.742734593837535) 27%,
		rgba(179, 122, 12, 0.8575805322128851) 100%
	);
`;

const HeaderRollItem = styled.div`
	display: flex;
	justify-content: center;
	width: 100%;
	padding: 8px 10px;
`;

const HamburgerIcon = styled.i`
	color: #fcfcfc;
	cursor: pointer;
	font-size: 24px;
	margin-right: 20px;
`;

const Container = styled.header`
	display: flex;
	align-items: center;
	height: 80px;
	//background: linear-gradient(
	//	90deg,
	//	rgba(189, 141, 98, 0.9416141456582633) 31%,
	//	rgba(182, 124, 77, 0.8575805322128851) 53%,
	//	rgba(179, 122, 12, 0.8575805322128851) 100%
	//);

	background: rgb(255, 191, 0);
	background: linear-gradient(
		90deg,
		rgba(255, 191, 0, 0.8295693277310925) 12%,
		rgba(255, 191, 0, 0.742734593837535) 27%,
		rgba(179, 122, 12, 0.8575805322128851) 100%
	);
	padding: 0 256px;
	position: sticky;
	top: 0;
	z-index: 10;

	@media (max-width: 1680px) {
		padding: 0 128px;
	}

	@media (max-width: 1280px) {
		padding: 0 64px;
	}

	@media (max-width: 920px) {
		padding: 0 32px;
	}

	@media (max-width: 720px) {
		padding: 0 16px;
	}

	@media (max-width: 568px) {
		padding: 0;
	}
`;

const LogoWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 200px;
	height: 100%;
	cursor: pointer;

	background-color: transparent;

	@media (max-width: 568px) {
		width: 160px;
	}
`;

const Logo = styled.img`
	height: 55%;

	@media (max-width: 568px) {
		margin-left: 7px;
		height: 45%;
	}
`;

const LinksWrapper = styled.div`
	width: 100%;
	display: flex;
	justify-content: flex-end;
	align-items: center;
`;

const Name = styled(Link)`
	font-size: 18px;
	color: #fcfcfc;
	text-decoration: none;
	padding-right: 20px;

	&:hover {
		color: #ded9d9;
	}

	@media (max-width: 568px) {
		padding-left: 7px;
		padding-right: 8px;

		font-size: 17px;
	}

	@media (max-width: 400px) {
		padding-left: 5px;
		padding-right: 5px;
	}
`;

const SpecialName = styled.a`
	font-size: 18px;
	color: #fcfcfc;
	text-decoration: none;
	padding-right: 20px;

	@media (max-width: 576px) {
		padding: 0;
	}
`;

export default Header;
